@import 'colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.logo {
    max-width: 150px;
    margin-bottom: 30px;

    img {
        max-width: 100%;
    }
}

.logo_header {
    max-width: 130px;

    a {
        display: block;
        padding: 10px 0;

        img {
            max-width: 100%;
        }
    }

}

.global {
    &-pagination {
        button {
            font-size: 20px;
            color: $black;
            border: 0;
            outline: none;
            background: transparent;

            @media all and (max-width:768px) {
                font-size: 15px;
            }
        }

        &--number {
            border: 1px solid $orange-500;
            border-radius: 10px;
            margin: 8px;
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media all and (max-width:768px) {
                margin: 4px;
                height: 35px;
                width: 35px;
                border-radius: 5px;
            }

            &.active {
                background: $orange-500;
            }

            p {
                font-size: 20px;
                color: $black;
                margin-bottom: 0;

                @media all and (max-width:768px) {
                    font-size: 15px;
                }
            }
        }
    }

    &-aboutwrapper {
        padding: 60px 0;

        @media all and (max-width: 576px) {
            padding: 40px 0;
        }

        &.notop{
            @media all and (max-width: 576px) {
                padding-top: 0;
            } 
        }
    }

    &-bodytext {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 0;
    }

    &-lineclamp {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &-heading {
        text-transform: capitalize;
        font-size: 3rem;
        font-weight: 600;
        color: $black;
        margin-bottom: 0;

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 40px;
        }

        @media all and (max-width: 991px) {
            font-size: 35px;
            margin-bottom: 20px;
            line-height: 1.4;
        }

        @media all and (max-width: 576px) {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }

    &-subheading {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 0;

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 26px;
        }

        @media all and (max-width: 991px) {
            font-size: 24px;
            margin-bottom: 20px;
        }

        @media all and (max-width: 576px) {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }

    &-section {
        &--center {
            padding: 100px 0 60px;
            text-align: center;

            @media all and (max-width:576px) {
                padding: 40px 16px;
            }
        }
    }

    &-fw600 {
        font-weight: 600;
    }

    &-fwbold {
        font-weight: bold;
    }

    &-bgmenucard {
        background: url(../../assets/images/bg_cards.webp) no-repeat;
        background-position: center;
        background-size: cover;
        padding-block: 200px;

        @media all and (max-width:992px) {
            padding-block: 80px;
        }
    }

    &-orangeBtn {
        background: $orange-500;
        color: $black;
        border: 0;
        text-transform: capitalize;
        padding: 17px 20px;
        border-radius: 60px;
        font-size: 20px;
        font-weight: 500;
        display: inline-block;

        &:hover {
            background: $orange-600;
        }
    }

    &-label {
        font-size: 18px;
        text-transform: capitalize;
        color: $black;
        display: block;
    }

    &-bodywrapper {
        overflow: hidden auto;
    }

    // &-primeCarousel {

    //     &--carousel-dot-wrapper {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         gap: 10px;
    //         position: absolute;
    //         left: 50%;
    //         bottom: 25px;
    //         transform: translate(-50%, 3px);

    //         &--carousel-dot {
    //             background-color: $orange-200;
    //             width: 30px;
    //             height: 3px;
    //             border: 0;
    //         }
    //     }

    //     &--owl-theme {
    //         &--grid {
    //             display: grid;
    //             grid-template-columns: repeat(5, 1fr);
    //             grid-auto-rows: 1fr;
    //             gap: 16px;

    //             @media all and (max-width:576px) {
    //                 grid-template-columns: repeat(2, 1fr);
    //             }

    //             &item {
    //                 .img {
    //                     object-fit: cover;
    //                     object-position: center;
    //                     aspect-ratio: 1;
    //                 }
    //             }

    //         }

    //         .owl-nav {
    //             width: 250px;
    //             height: 50px;
    //             margin: auto;
    //             display: flex;
    //             justify-content: space-between;
    //             align-items: center;

    //             @media all and (min-width:992px) {
    //                 margin-block: 46px 100px;
    //             }

    //             .owl-prev,
    //             .owl-next {
    //                 margin: 0 !important;

    //                 &:hover {
    //                     background: transparent;
    //                 }

    //                 span {
    //                     background: $orange-500;
    //                     color: $black;
    //                     width: 42px;
    //                     height: 42px;
    //                     border-radius: 50%;
    //                     font-size: 50px;
    //                     display: inline-flex;
    //                     justify-content: center;
    //                     align-items: center;

    //                     &:hover {
    //                         background: $orange-600;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    &-blue {
        @include flex(flex,start ,end );
        gap: 40px;
        @media all and (max-width:576px) {
            flex-direction: column;
            align-items: start;
            gap: 20px;
        }
        &--strip {
            background: $blue-500 url("../../assets/images/bg_frame.webp") no-repeat right/auto;
            padding: 40px 0;

            @media all and (max-width:576px) {
                background: $blue-500;
            }


            &applytitle {
                font-size: 25px;
                font-weight: 500;
                color: $white;
                text-transform: capitalize;

                @media all and (max-width:576px) {
                    font-weight: 400;
                    margin-bottom: 4px;
                    font-size: 20px;
                }
            }

            &applybtn {
                font-size: 55px;
                font-weight: 700;
                color: $white;
                text-transform: capitalize;

                @media all and (max-width:576px) {
                    font-size: 32px;
                }
            }

            &arrow {
                font-size: 55px;
                color: $white;
                background: transparent;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s all;


                &:hover{
                    background: $orange-100;
                    transition: 0.3s all;
                }
            }
        }
    }

    &-bglpink {
        background: $red-10;
    }

    &-formlbl {
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 500;
    }

    &-forminput {
        border: 1px solid $text-500;
        border-radius: 10px;
        width: 100%;
        padding: 12px 10px;

        &:focus {
            box-shadow: 0 4px 10px 3px rgb(255 176 107 / 50%);
            border: 1px solid $orange-500;
            outline: 0;
        }
    }

    &-checkbox {
        margin: 0;
        display: inline-block;
        font-size: 30px;
    }

    &-radiobtn {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-left: 3rem;

        .form-check-input {
            font-size: 30px;
        }
    }

    &-events {
        padding: 100px 0;

        @media all and (max-width:768px) {
            padding: 40px 0;
        }

        &--list {
            margin-bottom: 100px;
            list-style: none;

            &content {
                border-radius: 10px;
                margin-bottom: 24px;
                padding: 30px;
                background: $orange-50;
            }

            &date {
                font-size: 20px;
                color: $white;
                padding: 10px;
                background: $blue-500;
                margin-bottom: 24px;
            }

            &time {
                font-size: 32px;
                font-weight: 600;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid $black;

                @media all and (max-width:768px) {
                    font-size: 24px;
                }
            }

            &location {
                font-size: 32px;
                font-weight: 400;

                @media all and (max-width:768px) {
                    font-size: 20px;
                }
            }

            &title {
                font-size: 32px;
                font-weight: 600;
                padding-bottom: 5px;
                border-bottom: 1px solid $black;

                @media all and (max-width:768px) {
                    font-size: 20px;
                }
            }
        }
    }

    &-newscard {
        padding: 100px 0;

        &--wrapper {
            margin-top: 100px;

            @media all and (max-width:768px) {
                margin-top: 50px;
            }
        }

        &--list {
            margin: 35px 0;
            background: $white;
            gap: 40px;
            display: grid;
            grid-template-columns: 30% calc(70% - 100px);
            align-items: center;

            @media all and (max-width:768px) {
                display: block;
                border-radius: 10px;
            }

            @media all and (min-width:1200px) {
                gap: 100px;
            }

            &two {
                grid-template-columns: 50% calc(50% - 40px);
                gap: 40px;
            }

            &item {
                display: flex;
                gap: 75px;
                margin-bottom: 35px;
                background: $white;
                align-items: center;
                border-radius: 10px;

                @media all and (max-width:768px) {
                    flex-direction: column;
                    gap: 25px;
                    margin-bottom: 45px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    gap: 50px;
                }
            }

            &image {

                @media all and (max-width:768px) {
                    width: 100%;
                }

                img {
                    max-height: 320px;
                    min-height: 320px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                }
            }

            &btn {
                font-size: 20px;
                font-weight: 600;
                color: $orange-500;
                border: 0;
                outline: none;
                background: transparent;
                text-transform: capitalize;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 18px;
                }
            }

            &desc {
                margin: 15px 0;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 10px 0;
                }
            }

            &title {
                font-size: 32px;
                font-weight: 600;
                position: relative;
                margin-bottom: 25px;

                &::before {
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 150px;
                    background: $blue-500;
                    top: calc(100% + 10px);
                    left: 0;
                }

                @media all and (max-width:992px) {
                    font-size: 24px;
                }
            }

            &subtitle {
                font-size: 20px;
                text-transform: capitalize;
                font-weight: 400;
                color: $black;
            }

            &content {
                padding: 20px;
            }

            &desc {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                line-clamp: 3;
            }
        }
    }

    &-fullBanner {
        margin-bottom: 150px;

        @media all and (max-width:992px) {
            margin-bottom: 50px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}

// .owl-carousel .owl-stage-outer {
//     @media all and (max-width:768px) {
//         margin-bottom: 40px;
//     }
// }

// Header Menu
.p-megamenu {
    justify-content: end;
    background: $orange-50;
    border: 0;
    border-radius: 0;
    padding-block: 0;

    .p-megamenu-panel {
        width: 100%;
        left: 0;
    }

    // Main Menu Links
    .p-megamenu-root-list {
        margin: 0;
        padding: 0;

        &>.p-menuitem {
            position: unset;

            @media (min-width: 991px) {
                &:nth-child(n+7) {
                    display: none;
                }
            }
        }

        .p-menuitem-link {
            border-bottom: 3px solid transparent;
            padding-block: 25px;
            text-decoration: none;
            font-weight: 600;

            .p-menuitem-icon {
                display: none;
            }

            @media all and (max-width:991px) {
                border-bottom: 2px solid $orange-500;
                width: calc(100% - 20px);
                margin: 0 auto;
                padding: 20px 10px;
            }

            @media (min-width: 991px) and (max-width:1200px) {
                padding: 25px 11px;
                font-size: 14px;
            }

            &:hover {
                background: $orange-100;
                border-bottom: 3px solid $orange-500;
            }


            .p-menuitem-text {
                color: $black;
                text-transform: capitalize;
                line-height: 1.3;

                @media all and (max-width:991px) {
                    font-size: 22px;
                    color: $black;
                }
            }


            .p-icon {
                @media all and (min-width:991px) {
                    display: none;
                }
            }
        }

        li.p-menuitem:nth-child(6) {
            background: $orange-500;
            color: #000;
            border: 0;
            text-transform: capitalize;
            padding: 14px 30px 11px;
            border-radius: 60px;
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            width: auto;

            @media all and (max-width:991px){
                // margin: 10px 10px 0bannertxt;
                margin: 0px 10px 0;
            }

            .p-menuitem-link{
                padding: 0;
                width: auto;

                &:hover{
                    background: $orange-500;
                }
            }
        }
    }

    // Sub Menu
    .p-megamenu-grid {
        padding: 20px 40px;

        @media all and (max-width:991px) {
            padding: 0px 16px;
        }

        ul.p-megamenu-submenu {
            padding-left: 0;
            border-left: 1px solid $orange-200;

            @media all and (max-width:991px) {
                border-left: 0;
            }

            .p-menuitem {
                .p-menuitem-link {
                    padding: 10px 20px;

                    @media all and (max-width:991px) {
                        padding: 8px 0;
                        color: $orange-700;
                        font-weight: 400;
                        border-bottom: 0;
                    }

                    &:is(:hover, .active) {
                        border-color: transparent;
                        background: transparent;

                        .p-menuitem-text {
                            color: $orange-800;
                        }
                    }
                }

                &:nth-child(6){
                    background: transparent;
                    padding: 0;
                }
            }
        }

        &>div:nth-of-type(1) {
            ul.p-megamenu-submenu {
                border-left: 0;

                li.p-megamenu-submenu-header {
                    font-size: 42px;
                    font-weight: 600;
                    line-height: 1.3;
                    text-transform: capitalize;

                    @media all and (max-width:991px) {
                        display: none;
                    }
                }
            }

        }

    }

    .p-menuitem.p-highlight>.p-menuitem-content {
        background: transparent;
    }

    .p-megamenu-panel {
        background: linear-gradient(135deg, rgb(255, 243, 233), rgb(255, 201, 154));
    }

    .p-submenu-header {
        background: transparent;
        color: $orange-700;

        @media all and (max-width:991px) {
            padding: 0;
            font-weight: 400;
            font-size: 22px;
        }
    }

    .p-megamenu-button {
        color: $orange-900;

        &:hover,
        &:focus {
            background: transparent;
            outline: 0;
            box-shadow: none;
        }
    }

    &.p-megamenu-mobile-active {
        .p-megamenu-root-list {
            @media all and (max-width:991px) {
                height: 100vh;
                background: linear-gradient(135deg, rgb(255, 243, 233), rgb(255, 201, 154));
                position: fixed !important;
                top: 70px !important;
                overflow: hidden auto;
                display: block !important;
                padding-bottom: 80px !important;
            }
            @media (min-width:768px) and (max-width:991px){
                top: 57px!important;
            }
        }
    }
}

.p-megamenu.p-megamenu-horizontal .p-megamenu-root-list>.p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
    background: $orange-300;
}
.p-megamenu .p-menuitem > .p-menuitem-content:hover {
    background: $orange-200;
}

.carousel {
    max-width: 2500px;
    margin: 0 auto;
}
.p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content{
    background: $orange-50;
}