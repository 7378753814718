@import '../../assets/styles/colors';

.st{
    &-heading{
        padding: 100px 0;
    }


    &-network{
        background: rgb(106,213,157);
        background: linear-gradient(344deg, rgba(106,213,157,1) 0%, rgba(106,213,157,1) 33%, rgba(255,255,255,1) 100%);
    }

    &-stories{
        background: #F1F1F1;
        padding: 100px 0;

        &--carouselitembox{
            background: $white;
            border-radius: 10px;
            padding: 40px;
            @media all and (max-width:768px){
                padding: 20px;
            }
        }

        &--carouselItem{
            display: grid;
            grid-template-columns: 45% calc(55% - 25px);
            gap: 25px;
            @media all and (max-width:991px){
               display: block;
            }
        }
    
        &-crimg{
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                @media all and (max-width:991px){
                    margin-bottom: 20px;
                    max-height: 300px;
                }
            }
        }
    }

    &-gallery{
        display: flex;
        padding-top: 100px;
        @media all and (max-width:991px){
            flex-wrap: wrap;
        }
        &--item{
            position: relative;
            flex-grow: 1;

            &::after{
                content: "";
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                position: absolute;
                z-index: 990;
                left: 0;
                top: 0;
            }
        }

        &--text{
            position: absolute;
            bottom: 40px;
            left: 40px;
            z-index: 999;
            color: $white;
            font-size: 45px;
            font-weight: 600;
        }

        &--img{
            height: 323px;
            object-fit: cover;
            width: 100%;
        }
    }

    
}