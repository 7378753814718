@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}


@import 'colors';

.cp{
    &-salaries{
        margin-bottom: 100px;
        @media all and (max-width:768px){
            margin-bottom: 50px;
        }
        &--wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            background: $orange-50;
            border-radius: 10px;
            padding: 30px;
        }
        &--amount{
            color: $orange-500;
            font-weight: 700;
        }
        &--name{
            font-size: 20px;
            font-weight: 600;
            color: $black;
            border-right: 1px solid $black;
            padding: 0 30px;
            margin: 0 30px;
            @media all and (max-width:768px){
                margin-left: 0;
                padding-left: 0;
            }
        }
    }
    &-whyinternship{
        background: $red-50;
        padding: 100px 0;
        margin: 0 0 100px 0;

        @media all and (max-width:768px){
            padding: 50px 0;
            margin-bottom: 50px;
        }
        
        &--carousel {
            margin-top: 50px;
            // @media all and (max-width:992px){
            //     margin-top: 90px;
            // }
            .owl-carousel .owl-item img{
                max-height: 400px;
                height: 100%;
                object-fit: cover;
            }
            .owl-theme {
                .owl-item {
                    border-radius: 15px;
                    overflow: hidden;
                }

                .carousel-dot-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    position: absolute;
                    left: 50%;
                    bottom: 25px;
                    transform: translate(-50%, 1px);

                    .carousel-dot {
                        background-color: $orange-200;
                        width: 30px;
                        height: 3px;
                        border: 0;
                    }
                }

                .owl-nav {
                    width: 210px;
                    height: 50px;
                    margin: 30px auto 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .owl-prev, .owl-next{
                        margin: 0!important;
                        &:hover{
                            background: transparent;
                        }
                        span{
                            background: $orange-500;
                            color: $black;
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            font-size: 50px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center; 
                            
                            &:hover{
                                background: $orange-600;
                            }
                        }
                    }
                }
            }
            &bannertxt{
                background: $white;
                border-radius: 10px;
                padding: 20px;
                padding: 45px;
                @media all and (max-width:768px){
                    padding: 20px;
                }
                h4{
                    font-size: 42px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 0;
                    @media all and (max-width:992px){
                        font-size: 22px;
                    }
                }

                p.global-bodytext{
                    min-height: 150px;
                }
                
            }
            &itembox{
                @media all and (max-width:991px){
                    height: auto;
                }
            }
        }
    }
    &-recruiters{
        margin: 0 0 100px 0;
        &--carouselitem{
            background-color: #F6F6F6;

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    &-howitworks{
        background: $red-800;
        padding: 100px 0;
        margin-top: 100px;

        @media all and (max-width:768px){
            padding: 50px 0;
            margin-top: 40px;
        }

        &--heading{
            color: $white;
            margin-bottom: 15px;
        }
        &--desc{
            color: $white;
        }
    }
    &-card{
        background: $white;
        padding: 30px;
        border-radius: 10px;
        @media all and (max-width:576px){
            padding: 20px;
        }
    }
}

[data-wrap="wrap"]{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
}