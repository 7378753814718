@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}


@import 'colors';

.card {
    &-upcoming {
        &--item {
            @include flex(flex, space-between, start);
            margin-bottom: 24px;
            padding: 30px;
            background: $orange-50;
            border-radius: 10px;
            gap: 30px;

            @media all and (max-width:991px){
                flex-direction: column;
                align-items: start;
                padding: 20px;
            }

        }
        &-content{
            display: grid;
            grid-template-columns: 300px calc(70% - 35px);
            gap: 35px;
            @media all and (max-width:768px){
                grid-template-columns: auto;
            }
            &img{
                max-height: 500px;
                overflow: hidden;

                img{
                    object-fit: cover;
                    object-position: center;
                    aspect-ratio: 16/9;
                }
            }
            .global-subheading{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            &img{

            }
        }
    }

    &-past{
        background: $blue-50;
    }
}