@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.wu {
    &-main {
        &--vacancy {
            position: relative;

            a {
                background: $red-400;
                padding: 60px 43px 20px;
                color: $white;
                text-transform: capitalize;
                font-size: 48px;
                font-weight: 600;
                line-height: 1.2;
                display: block;
                @media all and (max-width:991px){
                    font-size: 28px;
                    padding: 60px 20px 20px;
                }
            }

            &:after {
                content: "\f062";
                font-family: "Font Awesome 6 Free";
                position: absolute;
                bottom: 20px;
                right: 43px;
                font-weight: 600;
                transform: rotate(45deg);
                font-size: 35px;
                color: $white;
                transition: 0.3s all;

                @media all and (max-width: 576px) {
                    font-size: 30px;
                }
            }

            &:hover {
                a {
                    color: $black;
                    transition: 0.3s all;
                }

                &:after {
                    color: $black;
                    transform: rotate(90deg);
                    transition: 0.3s all;
                }
            }
        }

        &--academic {
            a{
                background: $red-600;
            }
        }
        &--bgwork{
            margin-top: -200px;
            position: relative;
            z-index: -1;
            @media all and (max-width:768px){
                margin-top: -50px;
            }
            img{
                object-fit: cover;
                width: 100%;
            }
        }

        &--workvalues {
            background: $red-900;
            padding: 27px 27px 80px;
            color: $white;
            height: 100%;

            h2 {
                text-transform: capitalize;
                margin-bottom: 46px;
                font-weight: 600;
            }

            p {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 0;
            }

            &.red800 {
                background: $red-800;
            }
        }

        &--core {
            margin-top: -100px;
            padding-bottom: 100px;
            @media all and (max-width:768px){
                margin-top: -20px;
            }

            &values {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-auto-rows: 1fr;

                @media all and (min-width:576px){
                    grid-template-columns: repeat(2, 1fr);  
                }

                @media all and (min-width:992px){
                    grid-template-columns: repeat(3, 1fr);  
                }

                li {
                    list-style: none;
                    background: $white;
                    padding: 42px 42px 87px;
                    color: $black;
                    @media all and (max-width:768px){
                        padding: 42px;
                    }

                    h4 {
                        text-transform: capitalize;
                        margin-bottom: 30px;
                        font-weight: 600;
                        font-size: 32px;

                        @media all and (min-width:992px) {
                            max-width: 95%;
                            min-height: 74px;
                        }
                    }

                    &:nth-child(1) {
                        @include flex(flex,center,center);
                        text-align: center;
                        @media all and (max-width:768px){
                            border: 1px solid #d4d4d4;
                        }
                    }

                    &:nth-child(2) {
                        background: $red-200;
                    }

                    &:nth-child(3) {
                        background: $red-300;
                    }

                    &:nth-child(4) {
                        background: $red-400;
                    }

                    &:nth-child(5) {
                        background: $red-500;
                    }

                    &:nth-child(6) {
                        background: $red-700;
                    }
                }
            }
        }
    }
}