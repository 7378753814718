
@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

@import 'colors';

.cc{
    &-collage {
        &--list{
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-auto-rows: 1fr;
            gap: 16px;

            &item {
                .img {
                    object-fit: cover;
                    object-position: center;
                    aspect-ratio: 1;
                }
            }
        }
        &--carousel-dot-wrapper {
            @include flex(flex,center ,center);
            gap: 10px;
            position: absolute;
            left: 50%;
            bottom: 25px;
            transform: translate(-50%, 3px);
            overflow-x: auto;
            @media all and (max-width:991px){
                width: 142px;
                scrollbar-width: 0;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
            

            &--carousel-dot {
                background-color: $orange-200;
                width: 30px;
                height: 3px;
                border: 0;

                &.active{
                    background-color: $orange-500;
                }
            }
        }

        &--owl-theme {
            &--grid {
                // display: grid;
                // grid-template-columns: repeat(5, 1fr);
                // grid-auto-rows: 1fr;
                // gap: 16px;
                // @media all and (max-width:576px){
                //     grid-template-columns: repeat(2, 1fr); 
                // }

                &item {
                    .img {
                        object-fit: cover;
                        object-position: center;
                        aspect-ratio: 1;
                    }
                }

            }

            .owl-nav {
                width: 250px;
                height: 50px;
                margin:40px auto 0;
                @include flex(flex,space-between,center);

                .owl-prev, .owl-next{
                    margin: 0!important;
                    @media all and (max-width:991px){
                        display: none;
                    }
                    &:hover{
                        background: transparent;
                    }
                    span{
                        background: $orange-500;
                        color: $black;
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        font-size: 50px;
                        @include flex(inline-flex,center,center);
                        
                        &:hover{
                            background: $orange-600;
                        }
                    }
                }
            }
        }
    }
}

#cc_carousel{
    display: block;
    @media all and (min-width:991px){
        display: none; 
    }
}

#cc_grid{
    display: none;
    @media all and (min-width:991px){
        display: grid; 
        .cc-collage--listitem{
            max-width: 240px;
        }
    }
}