@import '../../assets/styles/colors';

.ei{
    &-welcoming{
        padding: 100px 0;
        background: $orange-100;
    }

    &-note{
        background: $orange-200;
        border-radius: 10px;
        padding: 10px 30px;
        margin: 20px 0 100px 0;

        &--heading{
            font-size: 32px;
            font-weight: 600;
            color: $black;
        }

        &--desc{
            font-weight: 500;
        }
    }

    &-schedule{
        padding: 100px 0;

        &--item{
            margin-bottom: 200px;
            &-content{
                border-radius: 10px;
                margin-bottom: 24px;
                padding: 30px;
                background: $orange-50;
            }
            &-date{
                font-size: 20px;
                color: $white;
                padding: 10px;
                background: $blue-500;
                margin-bottom: 24px;
            }
            &-time{
                font-size: 32px;
                font-weight: 600;
                padding-bottom: 5px;
                margin-bottom: 5px;
                border-bottom: 1px solid $black;
            }
            &-location{
                font-size: 32px;
                font-weight: 400;
            }
            &-title{
                font-size: 32px;
                font-weight: 400;
                padding-bottom: 5px;
                border-bottom: 1px solid $black;
            }
        }
    }

    &-imgcards{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap:16px;
        @media all and (max-width:576px){
            grid-template-columns: repeat(1, 1fr);  
        }

        img{
            width: 100%;
            max-height: 550px;
        }
    }
    &-culture{
        margin: 50px auto 100px;
        &--card{
            background: $orange-800;
            padding: 120px 20px 20px;
            height: 100%;
        }
    }

    &-highlights{
        padding: 50px 0 100px 0;
        &--list{
            &-item{
                background: $blue-600;
                padding: 30px;
                border-radius: 10px;
                font-weight: 600;
                color: $white;
                margin-bottom: 24px;

                @media all and (max-width:576px){
                    padding: 10px 15px;  
                    margin-bottom: 15px;
                }
            }
        }
    }
}