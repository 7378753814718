@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.sa {
    &--carousel {
        margin-top: 50px;
        @media all and (min-width:992px) {
            padding-inline: 50px;
        }

        .owl-carousel .owl-item img {
            height: 100%;
            object-fit: cover;
            object-position: 100% 30%;

            @media all and (max-width:991px) {
                object-position: center;
            }
        }

        .owl-theme {
            .owl-item {
                border-radius: 15px;
                overflow: hidden;
                @media all and (max-width:768px){
                    padding: 0 12px;
                }
            }

            .carousel-dot-wrapper {
                @include flex(flex, center, center);
                gap: 10px;
                position: absolute;
                left: 50%;
                bottom: 25px;
                transform: translate(-50%, 1px);

                .carousel-dot {
                    background-color: $orange-200;
                    width: 30px;
                    height: 3px;
                    border: 0;

                    &.active {
                        background-color: $orange-500; 
                    }
                }
            }

            .owl-nav {
                width: 230px;
                height: 50px;
                margin: 30px auto 0;
                @include flex(flex, space-between, center);
                @media all and (max-width:768px){
                    margin-top: 0;
                }

                .owl-prev,
                .owl-next {
                    margin: 0 !important;
                    @media all and (max-width:768px){
                        display: none;
                    }

                    &:hover {
                        background: transparent;
                    }

                    span {
                        background: $orange-500;
                        color: $orange-500;
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        font-size: 28px;
                        @include flex(inline-flex, center, center);
                        position: relative;

                        &:after {
                            content: "\f054";
                            font-family: "Font Awesome 6 Free";
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 9;
                            font-weight: 600;
                            color: $black;
                            width: 100%;
                        }

                        &:hover {
                            background: $orange-600;
                        }
                    }
                }

                .owl-prev {
                    span {
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &bannertxt {
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, transparent, rgb(0 0 0 / 50%));
            width: 100%;
            padding: 45px;

            @media all and (max-width:768px) {
                padding: 20px;
            }

            h4 {
                font-size: 42px;
                font-weight: 600;
                color: $white;
                margin-bottom: 0;

                @media all and (max-width:992px) {
                    font-size: 22px;
                }
            }

            h6{
                color: $white;
                margin-bottom: 0;
                font-size: 26px;
                @media all and (max-width:992px) {
                    font-size: 16px;
                }
            }

        }

        &itembox {
            height: 700px;

            @media all and (max-width:576px) {
                height: 260px;
                background: #fcfcfc;
            }

            @media (min-width:576px) and (max-width:991px) {
                height: 400px;
                margin: 0 15px;
            }
            @media all and (max-width:991px){
                border-radius: 15px;
                overflow: hidden;
            }
        }
    }

    &-sitare {
        &--vision {
            background: $red-700;
            padding: 40px;
            p {
                color: $white;
                font-weight: 600;
                font-size: 32px;

                @media all and (max-width: 768px) {
                    font-size: 24px;
                }
            }
        }
    }
}
