@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.al{
    &-upcoming{
        background: $orange-50;
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 40px;

        &--header{
            @include flex(flex,start,center);
            gap: 50px;
            @media all and (max-width:768px){
                flex-direction: column;
                align-items: start;
            }
        }
        &--schedule{
            @include flex(flex,start,start);
            gap: 50px;
            margin: 20px auto 30px;
            li{
                @include flex(flex,start,center);
                @media all and (max-width:768px){
                    flex-direction: column;
                    align-items: start;
                }
            }
        }
        .global-subheading{
            margin-bottom: 0;
        }
    }

    &-past{
        background: $blue-50;
        &--options{
            margin-top: 25px;
            li{
                display: inline-block;
                position: relative;
                color: $blue-500;
                font-size: 24px;
                text-transform: capitalize;
                text-decoration: underline;
                padding-right: 10px;
                margin-right: 10px;

                &:after{
                    content: "";
                    width: 2px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: $blue-500;
                }

                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
    
}