@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}


@import 'colors';

.ul{
    &-facilites{
        background: $orange-50;
        padding-block: 50px;
        &--list{
            li{
                display: block;
                margin-bottom: 30px;

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }

    &-imgcards{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap:16px;
        padding: 100px 0;

        @media all and (max-width:481px){
            grid-template-columns: repeat(1, 1fr);  
        }

        img{
            width: 100%;
            max-height: 550px;
        }
    }
}