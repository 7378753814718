@import '../../assets/styles/colors';



.nf {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media all and (max-width:991px){
        padding: 0 15px;
    }

    &-notfound{
        box-shadow: 1px 1px 20px -3px $blue-400;
        max-width: 800px;
        width: 100%;
        text-align: center;
        padding: 35px;
        border-radius: 10px;

        &--code{
            font-size: 3rem;
            font-weight: 700;
            color: $blue-900;
            text-transform: capitalize;
        }
        &--title{
            font-size: 1.2rem;
            font-weight: 600;
            color: $blue-900;
            margin: 20px 0;
            text-transform: capitalize;
        }
        &--desc{
            font-size: 16px;
            color: $blue-900;
            margin: 20px 0 30px 0;
            text-transform: capitalize;
        }
        &--btn{
            border: 1px solid $black;
            font-size: 15px;
            color: $white;
            background: $blue-900;
            font-weight: 600;
            border-radius: 20px;
            text-transform: capitalize;
            padding: 10px 30px;
        }
    }
    
}