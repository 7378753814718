@import '../../assets/styles/colors';

.ua{
    &-upcoming{
        padding: 100px 0;

        &--text{
            font-size: 20px;
            font-weight: 500;
            color: $black;
        }
    }
}