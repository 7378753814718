@import '../../assets/styles/colors';

.al{
    &-topics{
        padding-left: 40px;
        margin: 40px auto 70px;

        li:not(:last-child){
            margin-bottom: 15px;
        }
    }
}