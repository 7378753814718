@import '../../assets/styles/colors';

.sh {
    &-advisors {
        padding: 80px 0;
        @media all and (max-width:991px) {
            padding: 130px 0 0;
        }
        // &--heading{
        //     font-size: 50px;
        //     font-weight: 600;
        //     color: $black;
        //     margin-bottom: 30px;
        // }
        &--grid {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-auto-rows: 1fr;
            gap: 30px 60px;

            @media (min-width:992px) and (max-width:1080px) {
                grid-template-columns: repeat(5, 1fr);
            }

            @media (min-width:481px) and (max-width:991px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media all and (max-width:481px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &--profilepic {
            max-width: 287px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 100%;
            aspect-ratio: 1;
        }

        &--info {
            width: 95%;
            padding: 15px 10px;
            border-radius: 10px;
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: #FEEF87;
            }
            &--text {
                font-size: 18px;
                font-weight: 600;
                color: $black;
                margin-bottom: 0;
            }
            &--divider {
                position: relative;
                margin: 10px 0;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-image: radial-gradient(circle, #000 2px, transparent 2px);
                    // background-size: 10px 1px;
                    background-size: 10px 2px;
                    margin-bottom: 3px;
                }
            }

            // New styles for the Link component
            &--link {
                text-decoration: underline;
                color: #0077b5; // LinkedIn blue

                &:hover {
                    color: #005582;
                }
            }
        }

        &--icon {
            display: flex;
            justify-content: flex-end;
            width: 95%;
            padding: 10px;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    &-viewalso {
        padding: 30px 0 80px 0;

        &--heading {
            font-size: 25px;
            font-weight: 600;
            color: $black;
            margin-bottom: 30px;
            text-transform: capitalize;
            padding-top: 40px;
            border-top: 1px solid $black;
        }

        &--list {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 30px;

            &-item {
                font-size: 45px;
                font-weight: 600;
                padding-right: 30px;
                color: $black;
                border-right: 1px solid $black;

                a {
                    color: $black;
                }
            }
        }
    }
}
