@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.fp {

    &-curriculum {
        &--header {
            background: $blue-500;
            border-radius: 10px;
            padding: 20px 40px;
            @include flex(flex,space-between,center);
            margin-bottom: 15px;

            @media all and (max-width:767px) {
                display: block;
            }

            @media (min-width:768px) and (max-width:991px) {
                padding: 10px 20px;
            }

            &-text {
                font-size: 30px;
                color: $white;
                text-transform: uppercase;
                margin-bottom: 0;
            }

            &-btn {
                font-size: 18px;
                font-weight: 600;
                color: $black;
                text-transform: capitalize;
                background: $orange-500;
                padding: 13px 28px;
                border-radius: 30px;
                outline: none;
                border: 0;

                @media all and (max-width:991px) {
                    font-size: 12px;
                }
            }
        }

        &--table {
            overflow: auto;
            background: $orange-50;
    
            &wrapper {
                border-collapse: separate;
                border-spacing: 10px;
                width: 100%;
            }
    
            &heading {
                background: $orange-200;
                padding: 10px;
                border-radius: 10px;
                font-size: 16px;
                font-weight: 700;
                color: $black;
                text-transform: uppercase;
                min-width: 165px;
                margin: 0 10px;
            }
    
            &-row-heading {
                background: $orange-200;
                padding: 10px;
                border-radius: 10px;
                min-width: 165px;
                margin: 0 10px;
                vertical-align: text-top;
            
                &--semester {
                    font-size: 18px;
                    font-weight: 700;
                    color: $orange-900;
                    text-transform: capitalize;
                    margin-bottom: 0;
                }
            
                &--credits {
                    font-size: 12px;
                    font-weight: 500;
                    color: $orange-900;
                    text-transform: capitalize;
            
                    &--core {
                        padding-block: 680px 100px;
            
                        @media (min-width:768px) and (max-width:1200px) {
                            padding-block: 300px 100px;
                        }
            
                        &values {
                            display: grid;
                            grid-template-columns: repeat(1, 1fr);
                            grid-auto-rows: 1fr;
            
                            @media all and (min-width:576px) {
                                grid-template-columns: repeat(2, 1fr);
                            }
            
                            @media all and (min-width:992px) {
                                grid-template-columns: repeat(3, 1fr);
                            }
            
                            li {
                                list-style: none;
                                background: $white;
                                padding: 42px 42px 87px;
                                color: $black;
            
                                @media all and (max-width:768px) {
                                    padding: 25px;
                                }
            
                                h4 {
                                    text-transform: capitalize;
                                    margin-bottom: 30px;
                                    font-weight: 600;
                                    font-size: 32px;
            
                                    @media all and (min-width:992px) {
                                        max-width: 95%;
                                        min-height: 74px;
                                    }
                                }
            
                                &:nth-child(1) {
                                    @include flex(flex,center,center);
                                    text-align: center;
                                }
            
                                &:nth-child(2) {
                                    background: $red-200;
                                }
            
                                &:nth-child(3) {
                                    background: $red-300;
                                }
            
                                &:nth-child(4) {
                                    background: $red-400;
                                }
            
                                &:nth-child(5) {
                                    background: $red-500;
                                }
            
                                &:nth-child(6) {
                                    background: $red-700;
                                }
                            }
                        }
                    }
                }
            
            }
        }
    }

    &-project{
        background: $orange-50;
        padding: 30px;
        border-radius: 10px;
        display: grid;
        grid-template-columns: 33% calc(66% - 40px);
        grid-gap: 40px;
        @media all and (max-width:991px){
            display: block;
        }

        &--thumbnail{
            max-height: 440px;
            overflow: hidden;
            @media all and (max-width:768px){
                margin-bottom: 30px;
            }
            img{
                width: 100%;
                object-fit: contain;
            }
        }

    }
}
