@import '../../assets/styles/colors';

.ei{
    &-welcoming{
        padding: 100px 0;
        background: $orange-100;
    }

    &-note{
        background: $orange-200;
        border-radius: 10px;
        padding: 10px 30px;
        margin: 20px 0 100px 0;

        &--heading{
            font-size: 32px;
            font-weight: 600;
            color: $black;
        }

        &--desc{
            font-weight: 500;
        }
    }

    &-imgcards{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        gap:16px;
        @media all and (max-width:576px){
            grid-template-columns: repeat(1, 1fr);  
        }

        img{
            width: 100%;
            max-height: 550px;
        }
    }
    &-culture{
        margin: 50px auto 100px;
        &--card{
            background: $orange-800;
            padding: 75px 20px;
            height: 100%;
            @media all and (max-width:576px){
                text-align: center;
            }
            li{
                display: block;
                color: $white;
                text-transform: capitalize;
                font-size: 32px;
            }
        }
    }
}