@import '../../assets/styles/colors';

.in {
    &-insights {
        padding: 100px 50px;
        background: url('../../assets/images/insights_bg.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media all and (max-width:992px){
            padding: 50px 20px 100px;
        }

        &--carousel {

            &item {
                background: $white;
                border-radius: 10px;
                padding: 30px;

                &--img {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;

                    &full {
                        width: 100%;
                        max-height: 100px;
                        border-radius: 10px;
                        margin-bottom: 12px;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                &--desc {
                    font-size: 16px;
                    color: $black;
                    line-height: 24px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 7;
                    -webkit-line-clamp: 7;
                    overflow: hidden;
                }

                &--btn {
                    font-size: 20px;
                    font-weight: 600;
                    color: $orange-500;
                    outline: none;
                    border: 0;
                    background: transparent;
                }
            }

            &-nav {
                position: absolute;
                // background: #000;
                right: 0;
                top: -80px;
                z-index: 999;
                width: 140px;

                button {
                    border: 1px solid #8C4B13;
                    background: #fff;
                    border-radius: 50%;
                    height: 50px;
                    width: 50px;
                    color: #000;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;

                    .icon {
                        font-size: 1.3rem;
                    }

                    &:hover,
                    &:focus {
                        color: #000;
                        opacity: 1;
                        background: #FF8923;
                    }
                }
            }
        }

        &--internship-carousel{
            position: relative;

            .p-carousel-prev{
                position: absolute;
                bottom: calc(100% + 30px);
                right: 80px;
                border: 1px solid #8C4B13;
                background: #fff;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                color: #000;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                font-size: 30px;

                &.p-disabled{
                    opacity: 0.3;
                }

                &:hover,
                &:focus {
                    color: #000;
                    opacity: 1;
                    background: #FF8923;
                }
                @media all and (max-width:768px){
                    bottom: -60px;
                }
            }

            .p-carousel-next{
                right: 0;
                @extend .p-carousel-prev;
            }
        }
    }

    &-partners {
        background: $orange-50;

        &--bottomspace {
            @media all and (min-width:992px){
                margin-bottom: 160px;
            }
        }

    }

    &-completed {
        background: $blue-50;
        padding: 100px 0;

        &--grid {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: 1fr;
            gap: 30px 16px;
            @media all and (min-width:576px){
                grid-template-columns: repeat(2, 1fr);
            }
            @media all and (min-width:992px){
                grid-template-columns: repeat(3, 1fr);
            }
            @media all and (min-width:1200px){
                grid-template-columns: repeat(4, 1fr);
            }

            &item {
                background: $white;
                border-radius: 10px;
                padding: 30px;

                &--img {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }

                &--heading {
                    font-size: 32px;
                    font-weight: 600;
                    color: $black;
                    margin-top: 15px;
                }

                &--title {
                    font-size: 20px;
                    color: $black;
                }

                &--desc {
                    font-size: 16px;
                    color: $orange-500;
                    line-height: 24px;
                }
            }
        }

        &--load-btn {
            border: 2px solid $orange-500;
            border-radius: 30px;
            font-size: 18px;
            font-weight: 600;
            color: $black;
            padding: 10px 30px;
            background: $white;
            text-transform: capitalize;
        }
    }
}