@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.sh {
    &-home {
        &--carousel {
            margin-top: 50px;

            @media all and (max-width:992px) {
                margin-top: 90px;
            }

            @media all and (min-width:992px) {
                padding-inline: 50px;
            }

            .owl-carousel .owl-item img {
                height: 100%;
                object-fit: cover;
                object-position: 100% 30%;

                @media all and (max-width:991px) {
                    object-position: center;
                    // object-fit: contain;
                }
            }

            .owl-theme {
                .owl-item {
                    border-radius: 15px;
                    overflow: hidden;
                    @media all and (max-width:768px){
                        padding: 0 12px;
                    }
                }

                .carousel-dot-wrapper {
                    @include flex(flex, center, center);
                    gap: 10px;
                    position: absolute;
                    left: 50%;
                    bottom: 25px;
                    transform: translate(-50%, 1px);

                    // @media all and (min-width:576px) {
                    //     left: 52%;
                    //     bottom: 25px;
                    //     transform: translate(-55%, 1px);
                    // }

                    // @media all and (min-width:991px) {
                    //     left: 51%;
                    //     bottom: 25px;
                    //     transform: translate(-55%, 1px);
                    // }


                    .carousel-dot {
                        background-color: $orange-200;
                        width: 30px;
                        height: 3px;
                        border: 0;

                        &.active{
                            background-color: $orange-500; 
                        }
                    }
                }

                .owl-nav {
                    width: 230px;
                    height: 50px;
                    margin: 30px auto 0;
                    @include flex(flex, space-between, center);
                    @media all and (max-width:768px){
                        margin-top: 0;
                    }

                    .owl-prev,
                    .owl-next {
                        margin: 0 !important;
                        @media all and (max-width:768px){
                            display: none;
                        }

                        &:hover {
                            background: transparent;
                        }

                        span {
                            background: $orange-500;
                            color: $orange-500;
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            font-size: 28px;
                            @include flex(inline-flex, center, center);
                            position: relative;

                            &:after {
                                content: "\f054";
                                font-family: "Font Awesome 6 Free";
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 9;
                                font-weight: 600;
                                color: $black;
                                width: 100%;
                            }

                            &:hover {
                                background: $orange-600;
                            }
                        }
                    }

                    .owl-prev {
                        span {
                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            &bannertxt {
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, transparent, rgb(0 0 0 / 50%));
                width: 100%;
                padding: 45px;

                @media all and (max-width:768px) {
                    padding: 20px;
                }

                h4 {
                    font-size: 42px;
                    font-weight: 600;
                    color: $white;
                    margin-bottom: 0;

                    @media all and (max-width:992px) {
                        font-size: 22px;
                    }
                }

                h6{
                    color: $white;
                    margin-bottom: 0;
                    font-size: 26px;
                    @media all and (max-width:992px) {
                        font-size: 16px;
                    }
                }

            }

            &itembox {
                height: 700px;

                @media all and (max-width:576px) {
                    height: 260px;
                    background: #fcfcfc;
                }

                @media (min-width:576px) and (max-width:991px) {
                    height: 400px;
                    margin: 0 15px;
                }
                @media all and (max-width:991px){
                    border-radius: 15px;
                    overflow: hidden;
                }
            }
        }
    }

    &-service {
        &--grid {
            display: grid;
            grid-template-columns: 33.33% 33.33% 33.33%;

            @media all and (max-width:500px) {
                grid-template-columns: 100%;
            }

            @media (min-width:500px) and (max-width:768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            &item {
                color: $white;
                padding: 100px 20px 40px;
                font-size: 52px;
                text-transform: capitalize;
                font-weight: 600;
                line-height: 1.2;
                @include flex(flex, space-between, end);
                position: relative;
                text-decoration: none;
                min-height: 264px;

                @media all and (max-width:991px) {
                    padding: 40px 12px;
                    font-size: 28px;
                    min-height: unset;
                }

                @media (min-width:991px) and (max-width:1200px) {
                    padding: 60px 20px 40px;
                    font-size: 38px;
                }

                &:after {
                    content: "\f35a";
                    // font-family: var(--bs-font-sans-serif);
                    font-family: "Font Awesome 6 Free";
                    position: absolute;
                    bottom: 36px;
                    right: 20px;
                    font-weight: 600;
                }

                &.box1 {
                    background: $orange-500;

                    @media all and (min-width:768px) {
                        grid-column: 1;
                        grid-row: 2 / span 2;
                    }
                }

                &.box2 {

                    background: $yellow-500;

                    @media all and (min-width:768px) {
                        grid-column: 2;
                        grid-row: 2;
                    }
                }

                &.box3 {

                    background:$blue-500;

                    @media all and (min-width:768px) {
                        grid-column: 3;
                        grid-row: 2;
                    }
                }

                &.box4 {

                    background: $red-300;

                    @media all and (min-width:768px) {
                        grid-column: 2;
                        grid-row: 3;
                    }
                }

                &.box5 {

                    background: $green-300;

                    @media (min-width:500px) and (max-width:768px) {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }

                    @media all and (min-width:768px) {
                        grid-column: 3;
                        grid-row: 3;
                    }
                }

                &:hover {
                    color: $black;
                }
            }
        }
    }

    &-innovative {
        background: url(../../assets/images/Gradient.webp) no-repeat;
        background-position: 100% 0%;
        background-size: cover;
        padding: 84px 0;

        @media all and (max-width:991px) {
            padding: 84px 0 20px;
        }

        @media all and (min-width:1400px) {
            background-size: contain;
        }

        &--list {
            margin: 0;
            padding: 40px 0;
            border-top: 1px solid $orange-500;

            li {
                display: block;
                margin-bottom: 60px;

                &:last-child {
                    margin-bottom: 0;
                }

                b {
                    font-size: 26px;
                    text-transform: capitalize;
                    display: block;
                    margin-bottom: 10px;
                    line-height: 1;
                }

                p {
                    margin-bottom: 0;
                    font-weight: 500;
                }
            }
        }
    }

    &-beyond {
        &--heading {
            padding: 100px 0 60px;
            text-align: center;

            @media all and (max-width:768px) {
                padding: 40px 0 0;
            }
            .global-heading{
                text-transform: unset;
            }
        }

        &--wrapper {
            // column-count: 3;
            padding: 0;
            margin: 0;
            // column-gap: 20px;
            display: grid;
            grid-template-columns: calc(33.33% - 13px) calc(33.33% - 13px) calc(33.33% - 13px);
            align-items: center;
            gap: 20px;

            @media all and (max-width:768px) {
                display: block;
            }

            &play {
                font-size: 70px;
                color: $white;
            }

            &-li {
                list-style: none;
                // margin-top: 20px;
                // position: relative;
                overflow: hidden;
                border-radius: 20px;
                z-index: 1;

                &:after {
                    content: "";
                    background: radial-gradient($orange-500, rgb(255 137 35 / 90%));
                    width: 240px;
                    height: 240px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    filter: blur(30px);
                    transform: translate(-10%, 20%) scale(1.5);
                    transition: 0.3s all;
                    z-index: 0;

                    @media all and (max-width:768px) {
                        width: 200px;
                        height: 200px;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        width: 240px;
                        height: 240px;
                    }
                }

                &:nth-child(1) {
                    @media all and (min-width:481px) {
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    &:after {
                        display: none;
                        // background: radial-gradient($yellow-500, rgb(253 231 76 / 90%));
                        // width: 240px;
                        // height: 240px;

                        // @media all and (max-width:768px){
                        //     width: 200px;
                        //     height: 200px;
                        // }
                        // @media (min-width:768px) and (max-width:991px){
                        //     width: 240px;
                        //     height: 240px;
                        // }
                    }
                }

                &:nth-child(3) {
                    &:after {
                        // background: radial-gradient($blue-400, rgb(51 137 214 / 90%));
                        background: radial-gradient($green-500, rgb(69 203 133 / 90%));
                        width: 180px;
                        height: 180px;

                        @media all and (max-width:991px) {
                            width: 160px;
                            height: 160px;
                        }
                    }
                }

                // &:nth-child(5) {
                //     &:after {
                //         background: radial-gradient($green-500, rgb(69 203 133 / 90%));
                //         width: 200px;
                //         height: 200px;
                //     }
                // }

                // &:nth-child(6) {
                //     &:after {
                //         background: radial-gradient($red-500, rgb(232 93 117 / 90%));
                //         width: 200px;
                //         height: 200px;
                //     }
                // }

                // &:nth-child(7) {
                //     &:after {
                //         background: radial-gradient($orange-800, rgb(140 75 19 / 90%));
                //         width: 200px;
                //         height: 200px;
                //     }
                // }

                // &:last-child{
                //     max-height: 218px;
                //     @media (min-width:991px) and (max-width:1399px){
                //         max-height: 185px;
                //     }
                // }

                &:hover:after {
                    width: 100%;
                    height: 100%;
                    transition: 0.3s all;
                }
            }

            &-inner {
                list-style: none;
                // margin-top: 20px;
                // position: relative;
                overflow: hidden;
                border-radius: 20px;
                z-index: 1;

                &:after {
                    content: "";
                    background: radial-gradient($blue-400, rgb(51 137 214 / 90%));
                    width: 180px;
                    height: 180px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    filter: blur(30px);
                    transform: translate(-10%, 20%) scale(1.5);
                    transition: 0.3s all;
                    z-index: 0;

                    @media all and (max-width:768px) {
                        width: 200px;
                        height: 200px;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        width: 240px;
                        height: 240px;
                    }
                }

                &:hover:after {
                    width: 280px;
                    height: 280px;
                    transition: 0.3s all;
                }
            }

            &-innersport {
                list-style: none;
                margin-top: 20px;
                overflow: hidden;
                border-radius: 20px;
                z-index: 1;

                &:after {
                    content: "";
                    background: radial-gradient($blue-900, rgb(0 45 86 / 90%));
                    width: 240px;
                    height: 240px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-radius: 50%;
                    filter: blur(30px);
                    transform: translate(-10%, 20%) scale(1.5);
                    transition: 0.3s all;
                    z-index: 0;

                    @media all and (max-width:768px) {
                        width: 200px;
                        height: 200px;
                    }

                    @media (min-width:768px) and (max-width:991px) {
                        width: 240px;
                        height: 240px;
                    }
                }

                &:hover:after {
                    width: 100%;
                    height: 100%;
                    transition: 0.3s all;
                }
            }

            &-img {
                width: 100%;
                object-fit: cover;
            }

            &-text {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 40px 30px;
                z-index: 1;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: end;
                flex-direction: column;

                @media all and (max-width:768px) {
                    padding: 20px;
                }

                &name {
                    display: block;
                    font-size: 32px;
                    font-weight: 500;
                    color: $white;
                    margin-bottom: 0;
                    text-transform: capitalize;

                    @media all and (max-width:768px) {
                        font-size: 26px;
                    }

                    @media(min-width:768px) and (max-width:1199px) {
                        font-size: 26px;
                    }
                }
            }

        }
    }

    &-news {
        background-color: $orange-50;

        &--subheading {
            font-size: 20px;
            font-weight: 700;
            color: $black;
            margin-bottom: 0;
        }

        &--articles {
            background: $white;
            padding: 50px;

            @media all and (max-width:576px) {
                padding: 40px 16px;
            }

            img {
                width: 100%;
                height: auto;
            }

            &display {
                margin-top: 1.5rem;
                @include flex(flex, space-between, center);

                .fa-arrow-up {
                    transform: rotate(45deg);
                    font-size: 25px;
                    cursor: pointer;
                }

                p {
                    font-size: 16px;
                    color: $black;
                    font-weight: 600;
                }
            }

            &item {
                background: $orange-50;
                padding: 10px;
                @include flex(flex, start, start);
                gap: 16px;

                &thumb {
                    max-width: 110px;
                    width: 100%;
                    height: auto;
                }

                &desc {
                    font-size: 14px;
                    font-weight: 500;
                    color: $black;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    margin-bottom: 0;
                }

                &more {
                    font-size: 14px;
                    font-weight: 500;
                    color: $blue-500;
                    text-transform: capitalize;

                    &:hover {
                        color: $blue-600;
                        text-decoration: underline;
                    }
                }

                &date {
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-200;
                }
            }
        }

        &--viewall {
            color: $orange-500;
            font-size: 15px;
            font-weight: 500;
            text-transform: capitalize;
            text-align: right;
            text-decoration: none;

            &:hover {
                color: $orange-600;
                text-decoration: underline;
            }
        }
    }

    &-upcoming {
        background: $orange-100;
        padding: 20px 35px;
        height: 100%;

        @media all and (max-width:576px) {
            padding: 20px;
        }

        @media (min-width:991px) and (max-width:1200px) {
            padding: 20px;
        }

        &--header {
            @include flex(flex, space-between, center);
            margin-bottom: 24px;

            p {
                font-size: 18px;
                font-weight: 600;
                color: $black;
                text-transform: capitalize;
                margin-bottom: 0;
            }

            &viewall {
                font-size: 18px;
                font-weight: 600;
                color: $blue-500;
                text-transform: capitalize;
                text-decoration: none;

                &:hover {
                    color: $blue-600;
                    text-decoration: underline;
                }
            }
        }

        &--events {
            &desc {
                font-size: 18px;
                color: $black;
                line-height: 26px;
                border-top: 1px solid $orange-500;
                padding-top: 20px;
                margin: 20px 0 24px;
            }

            &date {
                font-size: 50px;
                font-weight: 700;
                color: $orange-500;
                padding-bottom: 3px;
                border-bottom: 1px solid $black;

                @media all and (max-width:1200px) {
                    font-size: 32px;
                    font-weight: 600;
                }
            }

            &month {
                font-size: 15px;
                font-weight: 600;
                color: $orange-500;
                text-transform: capitalize;
            }
        }

        .p-carousel-next,
        .p-carousel-prev {
            font-size: 25px;
            color: $black;
        }
    }

    &-studentlife {
        &--grid {
            display: grid;
            grid-template-columns: 50% 25% 25%;
            margin-top: 100px;

            @media all and (max-width:768px) {
                grid-template-columns: repeat(2, [col] 50%);
                grid-template-rows: repeat(2, [row] 1fr);
            }

            &item {
                color: $white;
                padding: 180px 20px 40px;
                font-size: 36px;
                text-transform: capitalize;
                font-weight: 400;
                line-height: 1.2;
                overflow: hidden;

                @include flex(flex, space-between, end);
                position: relative;
                text-decoration: none;

                @media all and (max-width:992px) {
                    font-size: 24px;
                    padding: 20px 10px;
                    min-height: 150px;
                }

                span {
                    max-width: 75%;
                    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
                    backdrop-filter: blur(1px);
                    border-radius: 5px;
                }

                &:after {
                    content: "\f061";
                    font-family: "Font Awesome 6 Free";
                    position: absolute;
                    bottom: 36px;
                    right: 20px;
                    font-weight: 600;
                    transform: rotate(-45deg);
                    transition: 0.3s all;

                    background: transparent;
                    padding: 12px;
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                    @include flex(flex, center, center);

                    @media all and (max-width:992px){
                        bottom: 10px;
                        right: 0;
                    }
                }

                &:hover {
                    transition: 0.3s all;

                    &:after {
                        transform: unset;
                        background: $orange-500;
                    }
                }

                &.box1 {
                    grid-column: 1;
                    grid-row: 2 / span 2;
                    background: $orange-500;
                    @include flex(flex, center, center);
                    color: $black;
                    font-weight: 600;
                    font-size: 48px;
                    padding: 50px;

                    @media all and (max-width:768px) {
                        grid-column: col 1 / span 2;
                        grid-row: row 1;
                        font-size: 30px;
                        justify-content: start;
                        padding: 40px 20px;
                    }

                    @media (min-width:768px) and (max-width:992px) {
                        font-size: 38px;
                    }

                    &:after {
                        display: none;
                    }
                }

                &.box2 {
                    grid-column: 2;
                    grid-row: 2;
                    background: url(../../assets/images/hostel/hostel1_black_and_white.webp) no-repeat;
                    background-position: center;
                    background-size: cover;
                    transition: 03s all;

                    @media all and (max-width:768px) {
                        grid-column: 1;
                    }
                }

                &.box3 {
                    grid-column: 3;
                    grid-row: 2;
                    background: url(../../assets/images/home/cafeteria.webp) no-repeat;
                    background-position: center;
                    background-size: cover;

                    @media all and (max-width:768px) {
                        grid-column: 2;
                    }
                }
 
                &.box4 {
                    grid-column: 2;
                    grid-row: 3;
                    background: url(../../assets/images/home/gym_black_white.webp) no-repeat;
                    background-position: center;
                    background-size: cover;

                    @media all and (max-width:768px) {
                        grid-column: 1;
                    }
                }

                &.box5 {
                    grid-column: 3;
                    grid-row: 3;
                    background: url(../../assets/images/home/sport.webp) no-repeat;
                    background-position: center;
                    background-size: cover;

                    @media all and (max-width:768px) {
                        grid-column: 2;
                    }
                }
            }
        }
    }

    &-founder {
        height: 100%;

        &--img {
            max-height: 550px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &--quote {
            background: linear-gradient(to bottom, $green-400, $white);
            padding: 80px;
            height: 100%;
            @include flex(flex, center, start);
            flex-direction: column;

            @media all and (max-width:992px) {
                padding: 40px 16px;
            }
 
            h1 {
                color: $white;
                font-size: 48px;
                font-weight: 600;

                @media all and (max-width:992px) {
                    font-size: 36px;
                }
            }

            p {
                color: $green-800;
                font-size: 22px;
                line-height: 1.5;
                margin: 25px 0;
                font-weight: 600;

                @media all and (max-width:992px) {
                    line-height: 1.2;
                }
            }
        }
    }
}