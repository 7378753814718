@import '../../assets/styles/colors';


.cu{
    &-enquries{
        padding: 100px 0;
        @media all and (max-width:768px){
            padding: 50px 0;
        }

        &--item{
            border: 0;
            margin: 10px 0;
        }

        &--header{
            border-top: 1px solid $black;
        }

        &--link{
            color: $blue-500;
            cursor: pointer;
        }

        &--body{
            padding-top: 0;
            font-size: 20px;
            font-weight: 500;
            color: $black;

            @media all and (max-width:500px) {
                font-size: 15px;
            }

            @media (min-width:501px) and (max-width:768px) {
                font-size: 18px;
            }
        }

        &--button{
            font-size: 32px;
            font-weight: 600;
            color: $black;

            @media all and (max-width:500px) {
                font-size: 20px;
            }

            @media (min-width:501px) and (max-width:768px) {
                font-size: 25px;
            }

            &:not(.collapsed){
                color: $black;
                background-color: transparent;
                box-shadow: none;
            }

            
            &:not(.collapsed)::after{
                content: "\f068";
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                right: 20px;
                width: 30px;
                height: 30px;
                color: $orange-500;
                transform: translate(0, -50%);
                background-image: unset;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &::after{
                content: "\2b";
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                right: 20px;
                width: 30px;
                height: 30px;
                color: $orange-500;
                transform: translate(0, -50%);
                background-image: unset;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &:focus{
                box-shadow: none;
            }

        }
    }
}