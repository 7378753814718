@import 'colors';


.wu {
    &-vacancies{
        padding: 100px 0;
        &--list{
            &item{
                background: $orange-50;
                border-radius: 10px;
                padding: 20px;
                list-style: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                &--title{
                    font-size: 32px;
                    font-weight: 400;
                    color: $black;
                    text-transform: capitalize;
                }
                &--deatils{
                    font-size: 20px;
                    color: $orange-500;
                    text-transform: capitalize;
                    cursor: pointer;
                }
            }
            .offcanvas{
                width: 80vw;
                z-index: 99999;
            }
        }
    }
}