@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.footer_wrapper {
    background: $blue-900;
    padding-block: 50px;
}

.suf {
    &-contact {
        &--info {
            margin-bottom: 25px;

            &heading {
                color: $blue-200;
                text-transform: capitalize;
                font-weight: 500;
                font-size: 16px;
            }

            &text {
                color: $white;
                font-weight: 400;
                font-size: 14px;
                text-decoration: none;
                margin-bottom: 0;
            }
        }

        &--link {
            @media all and (max-width:768px){
                margin-block: 50px;
            }
            &heading {
                color: $orange-200;
                text-transform: capitalize;
                font-weight: 600;
                margin-bottom: 16px;
            }

            &option {
                column-count: 2;

                &s {
                    li {
                        list-style: none;

                        a {
                            color: $white;
                            text-transform: capitalize;
                            text-decoration: none;
                            padding: 7px 0;
                            display: inline-block;
                            &:hover{
                                color: $orange-200
                            }
                        }
                    }
                }
            }
        }

        &--social{
            &link{
                @include flex(flex,start,center);
                gap: 16px;
                margin: 0 0 40px;
                padding: 0;
                li{
                    list-style: none;
                    a{
                        background: $orange-400;
                        width: 48px;
                        height: 48px;
                        font-size: 26px;
                        border-radius: 50%;
                        color: $blue-900;
                        @include flex(flex,center,center);
                        &:hover{
                            background: $orange-500; 
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &-subscribe{
        position: relative;
        margin-bottom: 60px;
        &--field{
            background: $white;
            border-radius: 10px;
            padding: 10px 130px 10px 20px;
            width: 100%;
            border: 0;
            min-height: 47px;
            &:focus{
                box-shadow: none;
                outline: 0;
            }
        }
        &--button{
            background: $orange-500;
            color: $black;
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            text-transform: capitalize;
            font-size: 18px;
            border-radius: 10px;
            font-weight: 400;
            padding: 10px 20px;
            &:hover{
                background: $orange-600;  
            }
        }
    }
}