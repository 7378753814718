@import '../../assets/styles/colors';

.sh {
    &-journey {
        padding: 74px 0;
        @media all and (max-width: 576px){
            padding: 40px 0;
        }
    }

    &-banner{
        &--image{
            width: 100%;
            max-height: 50vh;
            border-radius: 10px;
            object-fit: cover;
            object-position: center;
        }
    }
}