@import '../../assets/styles/colors';

.sb {
    &-banner {
        background: linear-gradient($blue-200 45%, white 45%);
        padding-top: 110px;
        background-position: center;
        background-size: contain;

        &--image {
            overflow: hidden;
            border-radius: 10px;

            img {
                max-width: 100%;
            }
        }
    }

    &-blogpost{
        margin-bottom: 50px;
        &--carousel{
            position: relative;

            .p-carousel-prev, .p-carousel-next{
                position: absolute;
                top: calc(100% + 70px);
                right: 50%;
                transform: translateY(-50%);
                height: 100px;
                width: 50%;
                background: $orange-50;
                padding: 20px 30px;
                color: #000;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                font-size: 30px;
            }

            .p-carousel-next{
                @extend .p-carousel-prev;
                left: 50%;
            }

            &-icon{
                &-img{
                    width: 50px;
                    height: 50px;
                }
                
            }
            &-text{
                font-size: 28px;
                font-weight: 600;
                color: $black;
                text-transform: capitalize;
            }
            &-title{
                font-size: 16px;
                font-weight: 500;
                color: $black;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 150px;
                width: 100%;
            }
        }
    }


    &-blog {
        &--banner {
            border-radius: 20px;
            overflow: hidden;
            position: relative;

            &-img {
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                object-position: center;
            }

            &-category {
                position: absolute;
                bottom: 10px;
                left: 15px;
                background: $orange-300;
                border-radius: 10px;
                padding: 2px 6px;
                font-size: 12px;
                font-weight: 600;
                color: $black;
                text-transform: capitalize;
                margin-bottom: 0;
            }
        }

        &--title {
            font-weight: 600;
            color: $black;
            margin: 10px 0 17px 0;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--date {
            font-size: 13px;
            color: $black;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        &--item{
            cursor: pointer;
        }

    }

    &-relatedblog{
        padding: 100px 0;
        margin-top: 50px;
        border-top: 1px solid $black;


        &--carousel{
            position: relative;

            .p-carousel-prev,.p-carousel-next{
                position: absolute;
                top: calc(100% + 40px);
                right: 50%;
                transform: translateY(-50%);
                background: #fff;
                height: 50px;
                width: 20%;
                padding: 20px 30px;
                color: #000;
                opacity: 1;
                transition: all 0.3s ease-in-out;
                font-size: 30px;
                display: flex;
                justify-content: flex-end;

            }

            .p-carousel-next{
               @extend .p-carousel-prev;
               left: 50%;
            }
        }
    }

    &-blogs{
        margin-bottom: 120px;
    }
}