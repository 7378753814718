@import '../../assets/styles/colors';

.bl {
    &-banner {
        background: $blue-200;
        height: 60vh;
        @media all and (max-width:768px){
            height: 40vh;
            margin-top: 70px;
        }
        @media (min-width:768px) and (max-width:991px){
            margin-top: 50px;
        }

        &--heading {
            font-weight: 700;
        }

        &--subheading {
            font-weight: 600;
        }
    }

    &-blog {
        padding: 100px 0;
        position: relative;
        @media all and (max-width:991px){
            padding: 50px 0;
        }

        &:after {
            content: "";
            background: radial-gradient($blue-100, rgba(176, 209, 239, 0.9));
            width: 150px;
            height: 500px;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            filter: blur(100px);
            transition: 0.3s all;
            z-index: -1;

            @media all and (min-width:992px) {
                width: 50vw;
                height: 1200px;
                position: absolute;
                top: -150px;
                right: -280px;
            }
        }

        &--content {
            padding: 40px;
            background: #F6F6F6;

            @media all and (max-width:992px) {
                padding: 40px 15px;
            }
        }

        &--wrapper {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-auto-rows: 1fr;

            @media all and (max-width:992px) {
                display: block;
            }
        }
    }

    &-filters {
        background: $blue-50;
        padding: 20px 30px;
        border-radius: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;

        @media all and (max-width:768px) {
            flex-direction: column;
            gap: 15px;
            padding: 20px;
        }

        @media (min-width:769px) and (max-width:992px) {
            justify-content: flex-start;
            gap: 10px;
            padding: 20px;
        }

        &--text {
            font-size: 20px;
            color: $black;
            cursor: pointer;
        }

        &--select {
            background: $white;
            border-radius: 10px;
            min-width: 180px;
            height: 56px;
            padding: 10px;
            border: 0;
            font-size: 20px;
            color: $black;
            position: relative;

            &:focus {
                border: 0;
                outline: none;
            }

            &::after {
                content: "\f107";
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                right: 20px;
                width: 30px;
                height: 20px;
                background: #0000;
            }
            @media all and (max-width:768px){
                width: 100%;
                min-width: 180px;
            }
        }
    }

    &-posts {
        &--wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 1fr;
            gap: 75px;

            @media all and (max-width:992px) {
                grid-template-columns: repeat(2, 1fr);
                grid-auto-rows: 1fr;
                gap: 25px;
            }

            @media (min-width:576px) and (max-width:992px) {
                gap: 40px;
            }
        }

        &--banner {
            border-radius: 20px;
            overflow: hidden;
            position: relative;

            &-img {
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                object-position: center;
            }

            &-category {
                position: absolute;
                bottom: 10px;
                left: 15px;
                background: $orange-300;
                border-radius: 10px;
                padding: 2px 6px;
                font-size: 12px;
                font-weight: 600;
                color: $black;
                text-transform: capitalize;
                margin-bottom: 0;
            }
        }

        &--title {
            font-weight: 600;
            color: $black;
            margin: 10px 0 17px 0;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--date {
            font-size: 13px;
            color: $black;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        &--item{
            cursor: pointer;
        }
    }

    &-pagination {
        button {
            font-size: 20px;
            color: $black;
            border: 0;
            outline: none;
            background: transparent;

            @media all and (max-width:768px) {
                font-size: 15px;
            }
        }

        &--number {
            border: 1px solid $orange-500;
            border-radius: 10px;
            margin: 8px;
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media all and (max-width:768px) {
                margin: 4px;
                height: 35px;
                width: 35px;
                border-radius: 5px;
            }

            &.active {
                background: $orange-500;
            }

            p {
                font-size: 20px;
                color: $black;
                margin-bottom: 0;

                @media all and (max-width:768px) {
                    font-size: 15px;
                }
            }
        }
    }

    &-newsletter {
        background: $blue-500;
        border-radius: 10px;
        padding: 30px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px;
        margin: 150px 0 100px;

        @media all and (max-width:768px) {
            flex-direction: column;
            margin-top: 60px;
        }

        &--title {
            color: $white;
            width: 40%;

            @media all and (max-width:768px) {
                width: 100%;
            }
        }

        &--desc {
            color: $blue-50;
            font-size: 20px;
        }

        &--content {
            width: 50%;

            @media all and (max-width:768px) {
                width: 100%;
            }
        }

        &--subscribe {
            position: relative;

            // margin-bottom: 60px;
            &-field {
                background: $blue-50;
                border-radius: 10px;
                padding: 10px 130px 10px 20px;
                width: 100%;
                border: 0;
                min-height: 47px;

                &:focus {
                    box-shadow: none;
                    outline: 0;
                }
            }

            &-button {
                background: $orange-500;
                color: $black;
                position: absolute;
                top: 0;
                right: 0;
                border: 0;
                width: 210px;
                text-transform: capitalize;
                font-size: 18px;
                border-radius: 10px;
                font-weight: 600;
                padding: 10px 20px;

                &:hover {
                    background: $orange-600;
                }
                @media all and (max-width:576px){
                    position: unset;
                    margin-top: 10px;
                }

                @media all and (max-width:992px) {
                    width: unset;
                }

            }
        }
    }
}