@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.su {
    &-header {
        &--upper {
            background: $blue-900;
            color: $white;

            @media all and (max-width:991px) {
                display: none;
            }

            &list {
                display: flex;
                align-items: center;
                justify-content: end;
                margin: 0;

                li {
                    list-style: none;

                    a {
                        color: $white;
                        font-weight: 600;
                        text-decoration: none;
                        text-transform: capitalize;
                        padding: 10px 20px;
                        display: block;

                        &:is(:hover, .active) {
                            background: $blue-500;
                        }
                    }
                }
            }
        }

        &--menu {
            background: $orange-50;
            position: sticky;
            top: 0;
            z-index: 99999;

            @media all and (max-width:991px) {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                z-index: 9;
            }
        }
    }
}

.p-megamenu-button{
    svg{
       @media all and (max-width:992px){
        width: 22px;
        height: 22px;
       }
    }
}