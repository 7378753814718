@import '../../assets/styles/colors';

@mixin flex($flex, $jc, $ai) {
    display: $flex;
    justify-content: $jc;
    align-items: $ai;
}

.al {
    &-filters {
        background: $green-50;
        padding: 20px 30px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-wrap: wrap;

        @media all and (max-width:768px) {
            flex-direction: column;
            gap: 15px;
        }

        @media (min-width:769px) and (max-width:992px) {
            justify-content: flex-start;
            gap: 15px;
        }

        &--wrapper{
            @include flex(flex,start,center);
            gap: 20px;
            width: 100%;

            @media all and (max-width:768px){
                flex-direction: column;
                align-items: start;
            }
        }

        &--text {
            all: unset;
            font-size: 20px;
            color: $black;
            cursor: pointer;
            white-space: nowrap;
        }

        &--select {
            background: $white;
            border-radius: 10px;
            min-width: 200px;
            height: 56px;
            padding: 10px;
            border: 0;
            font-size: 20px;
            color: $black;
            position: relative;

            @media all and (max-width:768px){
                width: 100%;
            }

            &:focus {
                border: 0;
                outline: none;
            }

            &::after {
                content: "\f107";
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                right: 20px;
                width: 30px;
                height: 20px;
                background: #0000;
            }
        }
    }

    &-gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: 30px;
        @media all and (max-width:481px){
            grid-template-columns: repeat(1, 1fr); 
        }

        @media (min-width:481px) and (max-width:768px){
            grid-template-columns: repeat(2, 1fr); 
        }

        &--image {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }

}