@import '../../assets/styles/colors';


.ts {

    &-red100 {
        background: $red-100;
    }
    &-media{
        background: $red-10;
        padding-block: 100px;
        @media all and (max-width:768px){
            padding-block: 50px;
        }
    }

    &-btn{
        background: $orange-500;
        border-radius: 30px;
        padding: 15px 40px;
        border: 0;
        color: $black;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
    }

    &-leaders{
        padding: 100px 20px;

        @media all and (max-width:768px){
            padding: 60px 20px 100px;
        }

        &--carouselitem{
            height: 469px;
            display: flex;
            border-radius: 10px;
            overflow: hidden;

            @media all and (max-width:992px){
                flex-direction: column;
                height: unset;
            }

            &-img{
                width: 70%;
                height: 100%;
                object-fit: cover;
                object-position: center;

                @media all and (max-width:992px){
                    height: 250px;
                    width: 100%;
                }
            }

            &-content{
                width: 30%;
                padding: 40px 20px;
                background: rgb(69,203,133);
                background: linear-gradient(315deg, rgba(69,203,133,1) 0%, rgba(69,203,133,1) 29%, rgba(51,137,214,1) 100%);

                @media all and (max-width:992px){
                    width: 100%;
                }
            }

        }
    }

    &-inspire{
        background: $orange-50;
        padding: 100px 0;
        @media all and (max-width:991px){
            padding: 40px 0;
        }
    }
}

[data-wrap="wrap"] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
}