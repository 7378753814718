@import "../../assets/styles/colors";

.sh {
    &-contentimgsplit {
        display: flex;
        justify-content: center;
        max-width: 2500px;
        margin: 0 auto;

        @media all and (max-width: 991px) {
            flex-direction: column;
            margin-top: 57px;
        }

        &--btn{
            // position: absolute;
            // left: 50px;
            // top: 50px;
            height: 50px;
            width: 50px;
            border: 2px solid $black;
            border-radius: 50%;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            &-arrow {
                font-size: 23px;
            }

            @media all and (max-width:768px){
                display: none;
            }
        }

        &--contain {
            @media all and (max-width:768px) {
                max-width: 540px;
                margin: 0 auto;
                padding: 0 16px;
                width: 100%;
            }
            @media (min-width:768px) and (max-width:991px) {
                max-width: 720px;
                margin: 0 auto;
                padding: 0 16px;
                width: 100%;
            }
        }

        &--content {
            background: $red-300;
            padding: 50px;
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: start;
            position: relative;

            @media all and (max-width: 991px) {
                width: 100%;
                padding: 40px 0px;
            }

            &-title {
                font-size: 15px;
                color: $black;
                text-transform: capitalize;
                font-weight: 700;
            }

            &-desc {
                font-size: 3rem;
                color: $black;
                font-weight: bold;
                margin-bottom: 0;
                line-height: 55px;

                @media all and (max-width: 1200px) {
                    font-size: 35px;
                    line-height: 45px;
                }
            }
        }

        &--yellow-theme {
            background: $yellow-500;
        }

        &--orange-theme {
            background: $orange-400;
        }

        &--orange500-theme {
            background: $orange-500;
        }

        &--red-theme {
            background: $red-300;
        }

        &--red700-theme {
            background: $red-700;
           p{
            color: $white!important;
           }
        }

        &--red300-theme {
            background: $red-700;
        }

        &--green500-theme {
            background: $green-500;
        }

        &--image {
            width: 60%;

            @media all and (max-width: 991px) {
                width: 100%;
            }

            &img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                max-height: 600px;

                @media all and (min-width:991px){
                    min-height: 490px;
                }
            }
        }
    }

    &-menuCard {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        gap: 16px;

        @media (min-width:576px) and (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media all and (max-width:576px) {
            grid-template-columns: repeat(1, 1fr);
        }

        &--grid {
            background: $red-500;
            border-radius: 10px;
            padding: 20px;
            height: 218px;
            display: flex;
            align-items: flex-end;
            position: relative;
            cursor: pointer;
            transition: 0.4s all;

            @media all and (max-width: 576px) {
                height: 166px;
            }

            &:after {
                content: "\f062";
                font-family: "Font Awesome 6 Free";
                position: absolute;
                top: 15px;
                right: 25px;
                font-weight: 600;
                transform: rotate(45deg);
                font-size: 35px;

                @media all and (max-width: 576px) {
                    font-size: 30px;
                }
            }

            &content {
                font-size: 50px;
                font-weight: 600;
                line-height: 50px;
                text-transform: capitalize;

                @media all and (max-width: 576px) {
                    font-size: 30px;
                }

                @media (min-width: 577px) and (max-width: 1200px) {
                    font-size: 35px;
                }
            }

            &:not(.disabled):hover {
                color: $white;
                padding-bottom: 30px;

                &content {
                    color: $white;
                }
            }
        }

        &--yellow-theme {
            background: $yellow-300;

            &:hover {
                color: $orange-500;

                p {
                    color: $orange-500;
                }
            }
        }

        &--orange-theme {
            background: $orange-400;
        }

        &--green-theme {
            background: $green-300;

            &:hover {
                color: $green-500;

                p {
                    color: $green-800;
                }
            }
        }
    }
}

.text-black{
   color: $black; 
}
.text-white{
   color: $white; 
}

.white-border{
    border: 2px solid $white;
}

.disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.wu {
    &-vacancy {
        &--title {
            font-size: 20px;
            font-weight: 600;
            color: $black;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        &--description {
            font-size: 20px;
            font-weight: 400;
            color: $black;
            text-transform: capitalize;
            margin-bottom: 0;
        }

        &--list {
            padding-left: 60px;
            margin-bottom: 50px;

            li {
                list-style-type: disc;
            }
        }

        &--close {
            cursor: pointer;
            &icon{
                max-width: 30px;
                width: 100%;
                height: auto;
                aspect-ratio: 1;
                margin-right: 0.5rem;
            }
        }

        &--short-info {
            margin: 30px 0;
        }
    }
}

.rw {
    &-growth {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: flex-end;

        @media all and (max-width:992px) {
            flex-wrap: wrap;
        }

        &--step {
            border-top: 10px solid $green-800;
            background: $green-50;
            padding: 30px;

            &:nth-child(2) {
                min-height: 310px;
            }

            &:nth-child(3) {
                min-height: 350px;
            }

            &:nth-child(4) {
                min-height: 400px;
            }

            &--title {
                font-size: 32px;
                font-weight: 600;
                color: $orange-500;
                text-transform: capitalize;
            }

            &--desc {
                font-size: 20px;
                font-weight: 500;
                color: $green-800;
                text-transform: capitalize;
            }
        }

        .yellow {
            background: $yellow-200;
        }

        .green {
            background: $green-50;
        }
    }
}

.st {
    &-stories {
        &--item {
            display: flex;
            gap: 75px;
            margin-bottom: 35px;
            background: $white;
            align-items: center;
            border-radius: 10px;

            @media all and (max-width:768px) {
                flex-direction: column;
                gap: 25px;
                margin-bottom: 45px;
            }

            @media (min-width:769px) and (max-width:992px) {
                gap: 50px;
            }

            &-image {
                height: 320px;
                width: 320px;
                aspect-ratio: 1;

                @media all and (max-width:768px) {
                    width: 100%;
                }

                @media (min-width:769px) and (max-width:992px) {
                    height: 250px;
                    width: 250px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                }
            }

            &-btn {
                font-size: 20px;
                font-weight: 600;
                color: $orange-500;
                border: 0;
                outline: none;
                background: transparent;
                text-transform: capitalize;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 18px;
                }
            }

            &-desc {
                margin: 15px 0;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 10px 0;
                }
            }

            &-title {
                font-weight: 600;
                margin-bottom: 15px;

                @media all and (max-width:768px) {
                    font-size: 24px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 24px;
                }
            }

            &-subtitle {
                font-size: 23px;
                font-weight: 500;

                @media all and (max-width:768px) {
                    font-size: 21px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 19px;
                }
            }
        }
    }
}

.is {
    &-stories {
        &--item {
            &-image {
                height: 323px;
                width: 574px;
                aspect-ratio: 1;

                @media all and (max-width:768px) {
                    width: 100%;
                }

                @media (min-width:769px) and (max-width:992px) {
                    height: 300px;
                    width: 500px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                }
            }
        }
    }

    &-details {
        width: 80vw !important;
        z-index: 999999;

        &--body {
            background: $orange-50;
        }

        &--images {
            img {
                width: 100%;
                height: 435px;
                object-fit: cover;
                object-position: center;
                margin-bottom: 15px;
            }
        }

        .offcanvas-header {
            border-bottom: 1px solid $black;
        }
    }
}