@import '../../assets/styles/colors';


.pa{
    &-pastactivity{
        padding: 100px 0;

        &--text{
            font-size: 20px;
            font-weight: 500;
            color: $black;
        }
    }

    &-winners{
        background: $orange-800;
        padding: 100px 0;

        @media all and (max-width:768px){
            padding: 75px 0;
        }

        &--heading{
            color: $white;
        }

        &--list{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
        }
    
        &--item{
            border-radius: 10px;
            padding: 40px;
            flex-grow: 1; 

            &:first-child{
                background: linear-gradient(to top, #FFC700, #FFE58A);
            }

            &:nth-child(2){
               background: linear-gradient(to top, #B2B2B2, #E3E3E3); 
            }

            &:nth-child(3){
                background: linear-gradient(to top, #905830, #EEB891);
            }

            &-position{
                font-size: 52px;
                font-weight: 600;
                margin-bottom: 25px;

                @media all and (max-width:768px){
                    font-size: 42px;
                    margin-bottom: 10px;
                }
            }

            &-batch{
                font-size: 32px;
                font-weight: 500;
                color: $black;
                margin-bottom: 15px;

                @media all and (max-width:768px){
                    padding: 22px 0;
                    margin-bottom: 5px;
                    padding: 0;
                }
            }
        }
    }

    &-gallery{
        padding: 100px 0;
        @media all and (max-width:768px){
            padding: 50px 0;
        }

        &--list{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 1fr;
            gap: 16px;

            img{
                height: 310px;
                width: 100%;
                object-fit: cover;
                object-position: center;

                @media all and (max-width:576px){
                    height: 220px;
                }
            }

            @media all and (max-width:576px){
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}