@import '../../assets/styles/colors';


.nu{
    &-stories{
        padding: 100px 0;

        &--list{
            margin: 50px 0;
        }

        &--item{
            display: flex;
            gap: 75px;
            margin-bottom: 35px;
            background: $white;
            align-items: center;
            border-radius: 10px;

            @media all and (max-width:768px) {
                flex-direction: column;
                gap: 25px;
                margin-bottom: 45px;
            }
            @media (min-width:769px) and (max-width:992px) {
                gap: 50px;
            }
            &-image{
                height: 320px;
                width: 320px;
                aspect-ratio: 1;
                @media all and (max-width:768px) {
                    width: 100%;
                }

                @media (min-width:769px) and (max-width:992px) {
                    height: 250px;
                    width: 250px;
                }

                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-radius: 10px;
                }
            }
            &-btn{
                font-size: 20px;
                font-weight: 600;
                color: $orange-500;
                border: 0;
                outline: none;
                background: transparent;
                text-transform: capitalize;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 18px;
                }
            }
            &-desc{
                margin: 15px 0;

                @media all and (max-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 10px 0;
                }
            }
            &-title{
                font-weight: 600;
                position: relative;
                margin-bottom: 25px;

                &::before{
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 150px;
                    // background: $blue-500;
                    border-bottom: 2px solid $blue-500;
                    top: calc(100% + 10px);
                    left: 0;
                }

                @media all and (max-width:768px) {
                    font-size: 24px;
                }

                @media (min-width:769px) and (max-width:992px) {
                    font-size: 24px;
                }
            }
        }
    }
}