@import '../../assets/styles/colors';

.ei{
    &-welcoming{
        padding: 100px 0;
        background: $orange-100;
    }

    &-note{
        background: $orange-200;
        border-radius: 10px;
        padding: 10px 30px;
        margin: 20px 0 100px 0;

        &--heading{
            font-size: 32px;
            font-weight: 600;
            color: $black;
        }

        &--desc{
            font-weight: 500;
        }
    }

    // &-schedule{
    //     padding: 100px 0;

    //     &--item{
    //         margin-bottom: 200px;
    //         &-content{
    //             border-radius: 10px;
    //             margin-bottom: 24px;
    //             padding: 30px;
    //             background: $orange-50;
    //         }
    //         &-date{
    //             font-size: 20px;
    //             color: $white;
    //             padding: 10px;
    //             background: $blue-500;
    //             margin-bottom: 24px;
    //         }
    //         &-time{
    //             font-size: 32px;
    //             font-weight: 600;
    //             padding-bottom: 5px;
    //             margin-bottom: 5px;
    //             border-bottom: 1px solid $black;
    //         }
    //         &-location{
    //             font-size: 32px;
    //             font-weight: 400;
    //             @media all and (max-width:768px){
    //                 font-size: 24px;
    //             }
    //         }
    //         &-title{
    //             font-size: 32px;
    //             font-weight: 400;
    //             padding-bottom: 5px;
    //             border-bottom: 1px solid $black;
    //         }
    //     }
    // }

    &-connections{
        padding: 0 20px;
        &--carouselitem{
            position: relative;
            height: 400px;

            &::after{
                content: "";
                width: 100%;
                height: 100%;
                // background: $blue-700;
                position: absolute;
                z-index: 990;
                left: 0;
                top: 0;
            }

            &-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }

            &-content{
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 999;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 10px 20px;
                color: $white;
                left: 0;
                top: 0;

                h3{
                    font-size: 28px;
                }
            }
        }
    }
}