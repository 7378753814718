// Blues
$blue-50:#e6f0fa;
$blue-100: #B0D1EF;
$blue-200:#8ABBE8;
$blue-400:#3389D6;
$blue-500: #006BCC;
$blue-600: #0061ba;
$blue-700: #004C91;
$blue-900:#002D56;

// Global
$white: #fff;
$black:#000;

// Oranges
$orange-50: #FFF3E9;
$orange-100: #FFDABB;
$orange-200: #FFC99A;
$orange-300: #FFB06C;
$orange-400: #FFA14F;
$orange-500: #FF8923;
$orange-600: #e87d20;
$orange-700: #B56119;
$orange-800: #8C4B13;
$orange-900: #6B3A0F;

// Yellow
$yellow-500:#FDE74C;
$yellow-300:#FEEF87;
$yellow-200: #FEF4AD;

// Greens
$green-50: #ECFAF3;
$green-300: #82DCAD;
$green-400: #6AD59D;
$green-500: #45CB85;
$green-800: #267049;

// Red
$red-10: #FFF6F6;
$red-50: #FDEFF1;
$red-100: #F8CDD4;
$red-200: #F4B4C0;
$red-300: #F092A3;
$red-400: #ED7D91;
$red-500: #E85D75;
$red-600: #d3556a;
$red-700: #a54253;
$red-800: #803340;
$red-900: #612731;

// Grey
$text-200: #A3A3A3;
$text-500: #383838;

